import { Box } from '@playful/design_system';
import { HEADER_HEIGHT } from '@playful/frontend/layouts/MainLayout';
import React, { useEffect, useRef, useState } from 'react';

// an embedded player that evolves based on the projectSize and viewportFit.
export function EmbeddedPlayer(props: {
  src: string;
  projectSize: { w: number; h: number; viewportFit: string };
  fullScreen?: boolean;
}) {
  const { src, projectSize, fullScreen } = props;
  const playerRef = useRef<HTMLIFrameElement>(null);

  const [playerWidth, setPlayerWidth] = useState<number>(0);

  const aspectRatio = projectSize.w / projectSize.h;

  useEffect(() => {
    const playerEl = playerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];

      setPlayerWidth(entry.contentRect.width);
    });
    if (playerEl) {
      resizeObserver.observe(playerEl);
    }

    return () => {
      if (playerEl) {
        resizeObserver.unobserve(playerEl);
      }
    };
  }, []);

  let iframeHeight = projectSize.h;
  if (projectSize.w > playerWidth) {
    iframeHeight = playerWidth / aspectRatio;
  }

  let height = `${iframeHeight}px`;

  if (projectSize.viewportFit === 'scale-to-fit') {
    height = `calc(100vh - ${HEADER_HEIGHT}px)`;
  }

  if (fullScreen && projectSize.viewportFit !== 'scale-to-fit') {
    height = `max(calc(100vh - ${HEADER_HEIGHT}px), ${iframeHeight}px)`;
  }

  return (
    <Box
      ref={playerRef}
      as='iframe'
      src={src}
      border='none'
      height={height}
      width='100%'
      overflow={'hidden'}
    />
  );
}
