import { BoxProps, Image, useBreakpointValue } from '@chakra-ui/react';
import { Box, SimpleGrid, Text } from '@playful/design_system';
import type { ProjectInfo } from '@playful/runtime';
import React from 'react';

import { CommunityProjectsGrid } from '../components/ProjectGrids/CommunityProjects';
import { SectionHeading } from '../components/SectionHeading';
import { VirtualList } from '../components/VirtualList';
import { PreviewCard, UserCard } from '../explorer/ProjectThumbnailCard';
import { useUserContext } from './UserContext';
import { useUser } from './useUser';

type UserProjectsListProps = {
  userId: string;
  projectInfos: ProjectInfo[];
  initialShowcase?: ProjectInfo[];
};

const shareTutorialImage = '/assets/sharing-is-caring.png';

function CalloutBox(props: BoxProps) {
  return (
    <Box
      backgroundColor={'gray.50'}
      borderRadius={'xl'}
      p={6}
      mb={6}
      fontSize={'md'}
      maxWidth={'580px'}
      {...props}
    >
      {props.children}
    </Box>
  );
}

export function UserProfileProjectList({ userId, projectInfos }: UserProjectsListProps) {
  const { user } = useUserContext();
  const { user: profileUser } = useUser({ id: userId });
  const Card = user.id === profileUser?.id ? UserCard : PreviewCard;
  const isMyProfile = user.id === profileUser?.id;
  const columns = { base: 1, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, '2xl': 5 };
  const columnCount = useBreakpointValue<number>(columns, 'base');

  return (
    <Box px={{ base: 6, md: 12 }} py={{ base: 8, md: 10 }}>
      <SectionHeading>{`@${profileUser?.name}`}</SectionHeading>
      {projectInfos.length === 0 && (
        <Box display={'flex'} flexDirection={'column'} mt={4} gap={4}>
          {isMyProfile && (
            <CalloutBox>
              <Text color={'gray.700'}>
                Whoa, so empty! To fill your profile with projects, click “share” in the designer
                then go to the share settings to switch on “share on Playful”. It’s that easy.
                They’ll show up here and to in the community gallery.
              </Text>
              <Image
                mt={4}
                ml={-4}
                src={shareTutorialImage}
                alt={'share tutorial'}
                maxWidth={'280px'}
              />
            </CalloutBox>
          )}
          {!isMyProfile && (
            <>
              <CalloutBox>
                <Text color={'gray.700'}>
                  What a bummer. @{profileUser?.name} hasn&apos;t shared any projects to their
                  gallery yet, but there’s plenty more to explore by checking out some of our other
                  community creators below.
                </Text>
              </CalloutBox>
              <CommunityProjectsGrid />
            </>
          )}
        </Box>
      )}

      <Box py={4}>
        <VirtualList
          as={SimpleGrid}
          pb={6}
          columns={columns}
          columnCount={columnCount || 5}
          gap={{ base: 4, xs: 4, sm: 4, md: 6, lg: 6, xl: 6 }}
          items={projectInfos}
          renderItem={(info) => <Card key={info.id} projectId={info.id} />}
        />
      </Box>
    </Box>
  );
}
