import type { AxiosError } from '@playful/api';
import { Maintenance, NotFound, ServerError, UserNotFound } from '@playful/components';
import { GlobalHeaderWithLogo } from '@playful/frontend/layouts/GlobalLayout';
import React, { PropsWithChildren } from 'react';

import { useMainLayout } from '../layouts/MainLayout';
import { AccountAppBarSection } from '../user/AccountAppBarSection';

export const errCodes: Record<AxiosError['status'], string> = {
  404: 'Not found :(',
  500: 'Something went wrong',
};

export enum ErrorPageTypes {
  UserNotFound = 'user-not-found',
  NotFound = '404',
  InternalServerError = '500',
  Maintenance = 'maintenance',
}

export function getErrorProps(status: AxiosError['status'] = 500, suffix = ''): AxiosError {
  const fmtSuffix = suffix ? `: ${suffix}` : '';
  return { status, message: [errCodes[status] || errCodes[500], fmtSuffix].join('') };
}

export function getErrorType(status: AxiosError['status'] = 500, suffix = ''): ErrorPageTypes {
  switch (status) {
    case 404:
      return ErrorPageTypes.NotFound;
    case 500:
      return ErrorPageTypes.InternalServerError;
    default:
      return ErrorPageTypes.InternalServerError;
  }
}

function RenderErrorPageByType(props: { type: ErrorPageTypes }) {
  const { type, ...rest } = props;
  switch (type) {
    case ErrorPageTypes.UserNotFound:
      return <UserNotFound {...rest} />;
    case ErrorPageTypes.NotFound:
      return <NotFound {...rest} />;
    case ErrorPageTypes.Maintenance:
      return <Maintenance {...rest} />;
    default:
      return <ServerError {...rest} />;
  }
}

export type ErrorPageProps = PropsWithChildren<{
  type?: ErrorPageTypes;
  withHeader?: boolean;
}>;

export function ErrorPage({
  children,
  type = ErrorPageTypes.InternalServerError,
  withHeader,
}: ErrorPageProps) {
  const { Wrapper, Content } = useMainLayout();
  return (
    <Wrapper flexGrow={1}>
      {withHeader && (
        <GlobalHeaderWithLogo>
          <AccountAppBarSection />
        </GlobalHeaderWithLogo>
      )}
      <Content>
        <RenderErrorPageByType type={type} />
      </Content>
    </Wrapper>
  );
}
